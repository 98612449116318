"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eLearningStudentUpdateFinished = void 0;
const collectionReferences_1 = require("../config/collectionReferences");
async function eLearningStudentUpdateFinished({ docRef, sectionId, chapterId, moduleId, }) {
    const studentDocument = await collectionReferences_1.eLearningStudentsCollectionRef.doc(docRef).get();
    let { finishedChaptersIds, finishedModulesIds, finishedSectionsIds } = studentDocument.data();
    let status = '';
    const requiredChapterSections = await collectionReferences_1.SectionsCollectionRef.where('chapterId', '==', chapterId).get();
    const requiredModuleChapters = await collectionReferences_1.chaptersCollectionRef.where('moduleId', '==', moduleId).get();
    const requiredChapterSectionsIds = requiredChapterSections.docs.map((e) => {
        return e.id;
    });
    const requiredModulesChaptersIds = requiredModuleChapters.docs.map((e) => {
        return e.id;
    });
    if (!finishedSectionsIds.includes(sectionId)) {
        finishedSectionsIds = [...finishedSectionsIds, sectionId];
        await studentDocument.ref.update({
            finishedSectionsIds,
        });
        status = 'finishedSections';
    }
    const allChapterSectionsFinished = requiredChapterSectionsIds.every((x) => finishedSectionsIds.includes(x));
    if (allChapterSectionsFinished && !finishedChaptersIds.includes(chapterId)) {
        finishedChaptersIds = [...finishedChaptersIds, chapterId];
        await studentDocument.ref.update({
            finishedChaptersIds,
        });
        status = 'finishedChapter';
    }
    const allModuleChaptersFinished = requiredModulesChaptersIds.every((x) => finishedChaptersIds.includes(x));
    if (allModuleChaptersFinished && !finishedModulesIds.includes(moduleId)) {
        finishedModulesIds = [...finishedModulesIds, moduleId];
        await studentDocument.ref.update({
            finishedModulesIds,
        });
        const viewRecordRef = await collectionReferences_1.modulesViewRecordsCollectionRef
            .where('studentId', '==', docRef)
            .where('moduleId', '==', moduleId)
            .get();
        viewRecordRef.docs.map((e) => {
            e.ref.update({ completed: true });
        });
        status = 'finishedAllModule';
    }
    if (finishedModulesIds.includes(moduleId)) {
        status = 'finishedAllModule';
    }
    return status;
}
exports.eLearningStudentUpdateFinished = eLearningStudentUpdateFinished;
