// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2STrgGktf6mZ_1_ibYwjKy {\n    align-items: center;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n}\n\n._1WA2ZEPqh9WE4tn2OVpohT {\n    margin: 16px;\n}\n\n._1q3yQBwD1UxZCmKRs01SwY {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    margin: 16px 0px;\n    flex: 1;\n    align-items: center;\n}\n._3JHOm1jPc7mqmwf4ViwJ2g {\n    /* font-size: 16px; */\n    text-transform: capitalize;\n    color: #333333bd;\n}\n\n._1lZN8b7NFuaAHba4CkSMml {\n    width: 340px !important;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "_2STrgGktf6mZ_1_ibYwjKy",
	"headerButton": "_1WA2ZEPqh9WE4tn2OVpohT",
	"procedureInputItem": "_1q3yQBwD1UxZCmKRs01SwY",
	"procedureInputLabel": "_3JHOm1jPc7mqmwf4ViwJ2g",
	"procedureAttSelect": "_1lZN8b7NFuaAHba4CkSMml"
};
module.exports = exports;
