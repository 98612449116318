// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Eq_agg_Q44fWZ1qHgUbGv {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.Laf1_ctTHI-xdAjgaUr2H {\n    color: royalblue;\n    display: flex;\n    cursor: pointer;\n}\n\n._2kr7wRAhYmJp9A4jQ38xp4 {\n    color: royalblue;\n    display: flex;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"arrowsContainar": "Eq_agg_Q44fWZ1qHgUbGv",
	"upIcon": "Laf1_ctTHI-xdAjgaUr2H",
	"downIcon": "_2kr7wRAhYmJp9A4jQ38xp4"
};
module.exports = exports;
