// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1rRRLN1K7jK4_iGSyuZakz {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    height: auto;\n}\n\n._1TacoTHic7QC5dQkuua-BH {\n}\n\n._2PCbdUPlsAJ9EUEGGLIE8x {\n    width: auto;\n}\n\n._2Mf_8v1-2nu8TieqGqxDCa {\n    margin: 12px 0;\n}\n", ""]);
// Exports
exports.locals = {
	"main": "_1rRRLN1K7jK4_iGSyuZakz",
	"select": "_1TacoTHic7QC5dQkuua-BH",
	"tag": "_2PCbdUPlsAJ9EUEGGLIE8x",
	"sharingListContainer": "_2Mf_8v1-2nu8TieqGqxDCa"
};
module.exports = exports;
