"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CloseEditorConfirm = void 0;
const antd_1 = require("antd");
const { confirm } = antd_1.Modal;
const lodash_1 = require("lodash");
function CloseEditorConfirm({ confirmMsg, onOkFunction, onCancelFunction, okText, cancelText, }) {
    confirm({
        title: confirmMsg,
        okText: okText !== null && okText !== void 0 ? okText : 'Ok',
        cancelText: cancelText !== null && cancelText !== void 0 ? cancelText : 'Cancel',
        onOk() {
            onOkFunction();
        },
        onCancel() {
            if (onCancelFunction !== undefined) {
                onCancelFunction();
            }
            lodash_1.noop();
        },
    });
}
exports.CloseEditorConfirm = CloseEditorConfirm;
