// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1JSUFG290BrwMnmWU3_nV6 {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n._3mxvpa4Ix8l6t-_vZ8a8DZ {\n    width: 400px;\n    height: 300px;\n    margin: 12px 12px;\n}\n\n._1ZGdJCWjG14BEugUzGxoNE {\n    position: relative;\n}\n\n._2_LkyxcpK4ZXx_oQw83bGR {\n    position: absolute;\n    top: 4px;\n    left: 12px;\n    color: red;\n    cursor: pointer;\n    z-index: 10;\n}\n\n.TZs0-tmXDH87JuXHTJOMT {\n    margin: 12px;\n    border: 1px solid #f2f2f2;\n    border-radius: 5px;\n    padding: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_1JSUFG290BrwMnmWU3_nV6",
	"list": "_3mxvpa4Ix8l6t-_vZ8a8DZ",
	"listContainer": "_1ZGdJCWjG14BEugUzGxoNE",
	"removeLevelIcon": "_2_LkyxcpK4ZXx_oQw83bGR",
	"listLablels": "TZs0-tmXDH87JuXHTJOMT"
};
module.exports = exports;
