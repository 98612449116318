// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2AfhV6qey3fvTFaLOkU9C9 {\n    width: 100%;\n    height: 100%;\n    padding: 16px;\n}\n\n._3ccEJopTkTrU5nnugFQOuA {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n    padding: 16px;\n}\n\n._36oPYrteZC5A35aUd9da8X {\n    display: flex;\n    flex-direction: row;\n}\n._2I3RA4SNwO7x22833fdL {\n    display: flex;\n    margin: 24px 16px;\n}\n._1bFuX5Jv4ZCFtyP5w1JxB4 {\n    display: flex;\n    flex-wrap: wrap;\n    flex-basis: 22.22222%;\n    margin: 18px 4px;\n}\n.P1sWPAsWl2YgovJFNgp5A {\n    margin: 2px 8px;\n}\n\n._3Gh-UiCluxI_1PRbSeOYyk {\n    display: flex;\n    align-items: center;\n}\n\n._2laABY76ACR22tBNxXc48L {\n    margin: 26px 0px;\n}\n\n._1iIMDqMlCencp148gIMGl0 {\n    display: flex;\n    margin: 0px 16px;\n    align-items: center;\n    background: #c48830;\n    color: #fff;\n    padding: 0px 8px;\n    border-radius: 4px;\n}\n\n.ZI6lQ-2-9BKk1cZh9Oolf {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n._2E2ven8FXWMQON0xnJJ21_ {\n    margin: 0px 6px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2AfhV6qey3fvTFaLOkU9C9",
	"permissionsListContainer": "_3ccEJopTkTrU5nnugFQOuA",
	"selectionButtonsContainer": "_36oPYrteZC5A35aUd9da8X",
	"selectionButton": "_2I3RA4SNwO7x22833fdL",
	"CheckboxItem": "_1bFuX5Jv4ZCFtyP5w1JxB4",
	"selectToggle": "P1sWPAsWl2YgovJFNgp5A",
	"sectionHeader": "_3Gh-UiCluxI_1PRbSeOYyk",
	"userName": "_2laABY76ACR22tBNxXc48L",
	"loginMethod": "_1iIMDqMlCencp148gIMGl0",
	"topInfo": "ZI6lQ-2-9BKk1cZh9Oolf",
	"methodIcon": "_2E2ven8FXWMQON0xnJJ21_"
};
module.exports = exports;
