// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3UNluzygxT6X0Y47hq-nBQ {\n    display: flex;\n    flex-direction: column;\n    padding: 50px 50px;\n}\n\n._3u9PldofQp3lCXqhVUpDlK {\n    padding: 16px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n._3u9PldofQp3lCXqhVUpDlK > button {\n    margin: 0 0 0 16px;\n}\n\n.WeGSdcvX86ruOLkClXxJ5 {\n}\n\n._3yuTLux-nO2wxBuWfQ7Em4 {\n    width: 80%;\n    padding: 50px;\n    border-radius: 5px;\n    box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.12);\n    top: 10%;\n    background-color: #fff;\n    position: absolute;\n}\n.Ryo1X3eqNVZcWwTK3WACv {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._1BGxfsJTfAAAWMheXHOe_g {\n    width: 100vw;\n    height: 100vh;\n}\n\n.JKsnkFAZHMRbs-rBkhlB6 {\n    width: 100%;\n    height: 150px;\n    background: #2c90ff;\n}\n\n._2pKxzLIT4uoVQJBTn0ppgf {\n    color: #fff;\n    font-size: 40px;\n    margin: 0 16px;\n    padding-top: 24px;\n    font-weight: 400;\n}\n._29NBaa8J63OoV_0VB0BktQ button {\n    position: fixed !important;\n    bottom: 5%;\n    right: 5%;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n}\n\n._18n2Xe5B6bPLEEY_cCjz8k {\n    display: flex;\n    padding: 20px 0pc;\n    border-bottom: 1px solid #f2f2f2;\n    margin-bottom: 24px;\n}\n", ""]);
// Exports
exports.locals = {
	"mainContainer": "_3UNluzygxT6X0Y47hq-nBQ",
	"buttonContainer": "_3u9PldofQp3lCXqhVUpDlK",
	"select": "WeGSdcvX86ruOLkClXxJ5",
	"mainContainerPublic": "_3yuTLux-nO2wxBuWfQ7Em4",
	"formPublic": "Ryo1X3eqNVZcWwTK3WACv",
	"formBackground": "_1BGxfsJTfAAAWMheXHOe_g",
	"CompanyFormHeader": "JKsnkFAZHMRbs-rBkhlB6",
	"companyName": "_2pKxzLIT4uoVQJBTn0ppgf",
	"publicSubmit": "_29NBaa8J63OoV_0VB0BktQ",
	"createCaseUrl": "_18n2Xe5B6bPLEEY_cCjz8k"
};
module.exports = exports;
