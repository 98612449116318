"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$isSoundCloudNode = exports.$createSoundCloudNode = exports.SoundCloudNode = void 0;
const LexicalBlockWithAlignableContents_1 = require("@lexical/react/LexicalBlockWithAlignableContents");
const LexicalDecoratorBlockNode_1 = require("@lexical/react/LexicalDecoratorBlockNode");
const React = __importStar(require("react"));
function SoundCloudComponent({ className, format, nodeKey, trackID, }) {
    const encodedUrl = encodeURIComponent(`https://api.soundcloud.com/tracks/${trackID}`);
    return (React.createElement(LexicalBlockWithAlignableContents_1.BlockWithAlignableContents, { className: className, format: format, nodeKey: nodeKey },
        React.createElement("iframe", { width: "100%", height: "166", scrolling: "no", src: `https://w.soundcloud.com/player/?url=${encodedUrl}&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true` })));
}
function convertSoundCloudElement(domNode) {
    const trackID = domNode.getAttribute('data-lexical-soundcloud');
    if (trackID) {
        const node = $createSoundCloudNode(trackID);
        return { node };
    }
    return null;
}
class SoundCloudNode extends LexicalDecoratorBlockNode_1.DecoratorBlockNode {
    constructor(id, format, key) {
        super(format, key);
        this.__id = id;
    }
    static getType() {
        return 'soundcloud';
    }
    static clone(node) {
        return new SoundCloudNode(node.__id, node.__format, node.__key);
    }
    static importJSON(serializedNode) {
        const node = $createSoundCloudNode(serializedNode.trackID);
        node.setFormat(serializedNode.format);
        return node;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            type: 'soundcloud',
            version: 1,
            trackID: this.__id,
        };
    }
    exportDOM() {
        const element = document.createElement('iframe');
        const encodedUrl = encodeURIComponent(`https://api.soundcloud.com/tracks/${this.__id}`);
        element.setAttribute('data-lexical-soundcloud', this.__id);
        element.setAttribute('width', '90%');
        element.setAttribute('height', '166');
        element.setAttribute('scrolling', 'no');
        element.setAttribute('frameborder', 'no');
        element.setAttribute('allow', 'autoplay');
        element.setAttribute('src', `https://w.soundcloud.com/player/?url=${encodedUrl}&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`);
        return { element };
    }
    static importDOM() {
        return {
            iframe: (domNode) => {
                if (!domNode.hasAttribute('data-lexical-soundcloud')) {
                    return null;
                }
                return {
                    conversion: convertSoundCloudElement,
                    priority: 1,
                };
            },
        };
    }
    updateDOM() {
        return false;
    }
    getId() {
        return this.__id;
    }
    getTextContent(_includeInert, _includeDirectionless) {
        return `https://soundcloud.com/${this.__id}`;
    }
    decorate(_editor, config) {
        const embedBlockTheme = config.theme.embedBlock || {};
        const className = {
            base: embedBlockTheme.base || '',
            focus: embedBlockTheme.focus || '',
        };
        return (React.createElement(SoundCloudComponent, { className: className, format: this.__format, nodeKey: this.getKey(), trackID: this.__id }));
    }
}
exports.SoundCloudNode = SoundCloudNode;
function $createSoundCloudNode(trackID) {
    return new SoundCloudNode(trackID);
}
exports.$createSoundCloudNode = $createSoundCloudNode;
function $isSoundCloudNode(node) {
    return node instanceof SoundCloudNode;
}
exports.$isSoundCloudNode = $isSoundCloudNode;
exports.default = SoundCloudNode;
