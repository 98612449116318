// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1AIsQTFxyYgp4oAbyNbKDu {\n    padding: 25px;\n}\n\n._3bT3dpud6eXzeRA6tCI6X1 {\n    border-bottom: 1px solid #f2f2f2;\n    padding-bottom: 20px;\n    padding-top: 20px;\n}\n._3dvd0TwYfoyJAJCbyTY5wF {\n    padding: 10px;\n    border-bottom: 1px solid #f2f2f2;\n}\n._18tD9fyVpRg2R9_MgfH8tw {\n    width: 165px;\n    color: #5c5c5c;\n    margin-right: 10px;\n    font-size: 13px;\n    display: inline-block;\n}\n\n._3RlWA92BiewpWgs-b4jwhr {\n    width: 75%;\n}\n\n._1p4pwWePxtSRwwagZ_BFfH {\n    width: 25%;\n}\n\n._2keS3xEQu-koF7cZV_iz2Y {\n    margin: 36px;\n}\n\n.PDLvR4WIdWDIKI4-wZu9K {\n    padding: 25px;\n    border: 1px #ccc;\n    border-radius: 5px;\n    cursor: pointer;\n    border-style: dashed;\n    background: #f2f2f2;\n}\n", ""]);
// Exports
exports.locals = {
	"profileBox": "_1AIsQTFxyYgp4oAbyNbKDu",
	"headerTitle": "_3bT3dpud6eXzeRA6tCI6X1",
	"infoBox": "_3dvd0TwYfoyJAJCbyTY5wF",
	"infoLabel": "_18tD9fyVpRg2R9_MgfH8tw",
	"formInputs": "_3RlWA92BiewpWgs-b4jwhr",
	"formInputsLang": "_1p4pwWePxtSRwwagZ_BFfH",
	"submitLangButton": "_2keS3xEQu-koF7cZV_iz2Y",
	"uploadB": "PDLvR4WIdWDIKI4-wZu9K"
};
module.exports = exports;
