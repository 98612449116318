"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.showConfirm = void 0;
const antd_1 = require("antd");
const { confirm } = antd_1.Modal;
function showConfirm(confirmMsg, onOkFunction) {
    confirm({
        title: confirmMsg,
        onOk() {
            onOkFunction();
        },
        onCancel() {
            /*  */
        },
    });
}
exports.showConfirm = showConfirm;
