"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useAsyncLoader(fn, deps = []) {
    const [result, setResult] = react_1.useState(undefined);
    const [error, setError] = react_1.useState(undefined);
    const [loading, setLoading] = react_1.useState(true);
    react_1.useEffect(() => {
        setLoading(true);
        fn()
            .then(setResult)
            .catch(setError)
            .finally(() => setLoading(false));
    }, deps);
    if (error) {
        throw error;
    }
    return [result, loading, error];
}
exports.default = useAsyncLoader;
