// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3BvIH8d01KuFdDGtTKlEX0 {\n    margin: 0 auto;\n    /* border: 1px solid #f2f2f2;\n    border-radius: 5px;\n    padding: 24px; */\n}\n\n._2Vw4duGDClpVIiSkzzKRQQ {\n    /* width: 100%; */\n    display: flex;\n    /* padding-top: 5px; */\n    /* border-bottom: 1px solid #f2f2f2; */\n    /* padding-bottom: 5px; */\n    justify-content: space-between;\n    align-items: center;\n}\n\n._3-kFnQPYv_LIXj7lUyqO7g {\n    font-size: 14px;\n    color: #8888;\n    font-style: italic;\n}\n", ""]);
// Exports
exports.locals = {
	"content": "_3BvIH8d01KuFdDGtTKlEX0",
	"titleBox": "_2Vw4duGDClpVIiSkzzKRQQ",
	"pagesNumberTitle": "_3-kFnQPYv_LIXj7lUyqO7g"
};
module.exports = exports;
