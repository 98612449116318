"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionSittings = void 0;
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const Confirm_1 = require("../../../../components/Common/Confirm");
const AddOptionFormModel_1 = require("./AddOptionFormModel");
const EditQuestionFormModel_1 = require("./EditQuestionFormModel");
exports.QuestionSittings = (props) => {
    const { formProps, question } = props;
    const [editingQuestionModel, setEditingQuestionModel] = react_1.useState(false);
    const [addingOption, setAddingOption] = react_1.useState(false);
    const { t } = react_i18next_1.useTranslation();
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", null,
            formProps.values.questions[question.id].priority === 0 ? null : (react_1.default.createElement(icons_1.UpOutlined, { style: { margin: '0px 5px', cursor: 'pointer', fontSize: 16, zIndex: 9999 }, onClick: () => {
                    formProps.setFieldValue(`questions[${question.id}].priority`, question.priority - 1.5);
                    formProps.setFieldValue('collapseDefaultOpened', question.priority - 1);
                } })),
            formProps.values.questions[question.id].priority + 1 ===
                Object.keys(formProps.values.questions).length ? null : (react_1.default.createElement(icons_1.DownOutlined, { style: { margin: '0px 5px', cursor: 'pointer', fontSize: 16, zIndex: 9999 }, onClick: () => {
                    formProps.setFieldValue(`questions[${question.id}].priority`, question.priority + 1.5);
                    formProps.setFieldValue('collapseDefaultOpened', question.priority + 1);
                } })),
            react_1.default.createElement(icons_1.EditOutlined, { style: { margin: '0px 5px', cursor: 'pointer', fontSize: 16, zIndex: 9999 }, onClick: () => {
                    setEditingQuestionModel(true);
                } }),
            react_1.default.createElement(icons_1.PlusOutlined, { style: { margin: '0px 5px', cursor: 'pointer', fontSize: 16, zIndex: 9999 }, onClick: () => {
                    setAddingOption(true);
                } }),
            react_1.default.createElement(icons_1.DeleteOutlined, { style: { margin: '0px 5px', cursor: 'pointer', fontSize: 16, zIndex: 9999 }, onClick: () => {
                    Confirm_1.showConfirm(t('app.examDesigner.areYouSureDeleteThisQuestion'), () => {
                        formProps.setFieldValue(`questions[${question.id}]`, undefined);
                    });
                } })),
        react_1.default.createElement("div", null,
            addingOption ? (react_1.default.createElement(AddOptionFormModel_1.AddOptionFormModel, { formProps: formProps, questionId: question.id, setVisible: setAddingOption, visible: addingOption })) : null,
            editingQuestionModel ? (react_1.default.createElement(EditQuestionFormModel_1.EditQuestionFormModel, { formProps: formProps, questionId: question.id, setVisible: setEditingQuestionModel, visible: editingQuestionModel })) : null)));
};
