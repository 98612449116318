"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
function removeObjectValues(jsonObject) {
    let result = {};
    lodash_1.map(jsonObject, (value, key) => {
        result = {
            ...result,
            [key]: '',
        };
    });
    return result;
}
function removeJsonValues(jsonObject) {
    let result = {};
    lodash_1.map(jsonObject, (value, key) => {
        result = {
            ...result,
            [key]: typeof value === 'object'
                ? removeObjectValues(value)
                : {
                    [key]: '',
                },
        };
    });
    return result;
}
exports.default = removeJsonValues;
