"use strict";
var xhrProto = XMLHttpRequest.prototype, origOpen = xhrProto.open;
xhrProto.open = function (method, url) {
    if (url.indexOf('gfdevex.gfeedback.net') != -1) {
        // @ts-ignore
        const result = origOpen.apply(this, arguments);
        // @ts-ignore
        this.setRequestHeader('idtoken', window._globalHeaders.idtoken);
        // @ts-ignore
        this.setRequestHeader('regionid', window._globalHeaders.regionid);
        return result;
    }
    // @ts-ignore
    return origOpen.apply(this, arguments);
};
