// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2dNjLdys5oCfdIFaxrPqnU {\n    padding: 16px;\n    background-color: white;\n    width: 100%;\n}\n\n._1aCz9lDEOChvtjhHwPIlJ1 {\n    display: flex;\n    flex-direction: column;\n}\n\n._3LyQFB-mo7-Kaaz1r4pm-4 {\n    width: 100%;\n    padding: 6px 0;\n    display: flex;\n}\n\n._2lphvOUTlGgMV9ZA2FeRgE {\n    font-size: 14px;\n    color: rgba(0, 0, 0, 0.65);\n    width: 260px;\n    min-width: 260px;\n    margin-right: 12px;\n    /* text-align: right; */\n    /* text-transform: capitalize; */\n}\n\n._2dNjLdys5oCfdIFaxrPqnU {\n    padding: 16px;\n    background-color: white;\n    width: 100%;\n}\n\n._1aCz9lDEOChvtjhHwPIlJ1 {\n    display: flex;\n    flex-direction: column;\n}\n\n._1_TLVeYIH_WiC7DM7xGsK3 {\n    display: flex;\n    justify-content: space-between;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2dNjLdys5oCfdIFaxrPqnU",
	"attributesContainer": "_1aCz9lDEOChvtjhHwPIlJ1",
	"formbody": "_3LyQFB-mo7-Kaaz1r4pm-4",
	"label": "_2lphvOUTlGgMV9ZA2FeRgE",
	"actionsContainer": "_1_TLVeYIH_WiC7DM7xGsK3"
};
module.exports = exports;
