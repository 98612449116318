"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addNewQuestionOption = exports.updateQuestion = exports.addNewQuestion = void 0;
const lodash_1 = require("lodash");
const collectionReferences_1 = require("../../../config/collectionReferences");
exports.addNewQuestion = (formProps, type, examId, titleLocalized, title, companyId) => {
    const newId = collectionReferences_1.examQuestionsCollectionRef.doc().id;
    const newPriority = Object.keys(formProps.values.questions).length + 1;
    const data = {
        id: newId,
        companyId: companyId,
        examId: examId,
        title: title,
        titleLocalized: titleLocalized,
        type: type,
        priority: newPriority,
    };
    formProps.setFieldValue(`questions.${newId}`, data);
};
exports.updateQuestion = (formProps, newType, newTitleLocalized, newTitle, questionId) => {
    formProps.setFieldValue(`questions.[${questionId}].title`, newTitle);
    formProps.setFieldValue(`questions.[${questionId}].titleLocalized`, newTitleLocalized);
    formProps.setFieldValue(`questions.[${questionId}].type`, newType);
    if (formProps.values.questions[questionId].type === 'multiSelect' && newType === 'select') {
        lodash_1.map(formProps.values.questions[questionId].options, (optionValue, id) => {
            formProps.setFieldValue(`questions[${questionId}].options[${optionValue.id}].correct`, false);
        });
    }
};
exports.addNewQuestionOption = (formProps, questionId, valueLocalized, value, companyId) => {
    const newId = collectionReferences_1.examQuestionsCollectionRef.doc().id;
    // const newPriority = Object.keys(formProps.values.questions[questionId].options).length + 1
    const data = {
        id: newId,
        companyId: companyId,
        questionId: questionId,
        value: value,
        valueLocalized: valueLocalized,
        active: true,
        correct: false,
    };
    formProps.setFieldValue(`questions.${questionId}.options[${newId}]`, data);
};
