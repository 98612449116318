"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GLOBAL_PERMISSIONS = void 0;
const IBJ_PERMISSIONS = [
    'manageUsers',
    'createNewCaseType',
    'editCaseTypeDesign',
    'editPermissions',
    'manageELearning',
    'manageBeneficiaries',
    'manageCommunitySpace',
    'manageLanguages',
    'beneficiariesView',
    'documentLibraryManage',
    'permissionToLoginFromMobile',
    'permissionToLoginFromWebPortal',
    'viewPublications',
    'viewELearning',
    'submitCase',
];
exports.GLOBAL_PERMISSIONS = process.env.APPLICATION_TYPE === 'IBJ'
    ? IBJ_PERMISSIONS
    : [
        'manageUsers',
        'manageGroups',
        'manageProjects',
        'sharedAttributes',
        'submitCase',
        'createNewCaseType',
        'editCaseTypeDesign',
        'changeAssignmentRolesInCaseType',
        'editCaseTypesProjects',
        'editCompanyConfiguration',
        'editPermissions',
        'permissionToLoginFromMobile',
        'permissionToLoginFromWebPortal',
        'documentLibraryManage',
        'beneficiariesView',
        'exportCases',
        'dashboardDesigner',
        'manageELearning',
        'beneficiarySatisfactionView',
        'portalUsersProfileDesgin',
        'beneficiaresProfileDesgin',
        'manageBeneficiaries',
        'manageCommunitySpace',
        'manageLanguages',
        'manageEmails',
    ];
