"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useUser_1 = require("./useUser");
function useUserId() {
    var _a, _b;
    const user = useUser_1.useUser();
    const userId = (_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'IsLoadingId';
    return userId;
}
exports.default = useUserId;
