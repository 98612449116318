"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cascadingOptionsToTree = void 0;
function cascadingOptionsToTree(options, maxCascadingLevel = Infinity, locale) {
    const clonedOptions = options
        .filter(option => option.level < maxCascadingLevel)
        .map(option => {
        const viewTitle = option.titleLocalized[locale] !== undefined ? option.titleLocalized[locale] : option.title;
        return { ...option, label: viewTitle, value: viewTitle };
    });
    clonedOptions.forEach(option => {
        if (option.parent) {
            const parent = clonedOptions.find(o => {
                return o.level === option.level - 1 && o.key === option.parent;
            });
            if (!parent) {
                return;
            }
            if (!parent.children) {
                parent.children = [];
            }
            parent.children.push(option);
        }
    });
    return clonedOptions.filter(option => option.level === 0);
}
exports.cascadingOptionsToTree = cascadingOptionsToTree;
