// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1WlTOgl_zA0tz5VK0Q1GkI {\n    display: flex;\n    width: 100%;\n    justify-content: center;\n    flex-direction: column;\n}\n._3d-dE77BzXpbzTSZbq_WTt {\n    display: flex;\n    justify-content: center;\n    height: auto;\n    width: 100%;\n    padding: 20px;\n}\n\n._1WW2eJs8T4KkF5GrsZ2Dz2 {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n._33igrWOxM-cG-oj-p5aIp1 {\n    padding: 16px;\n    display: flex;\n    justify-content: flex-end;\n}\n\n._33igrWOxM-cG-oj-p5aIp1 > button {\n    margin: 0 0 0 16px;\n}\n\n._1GFvrpcsZ8ZdwyawPhKe4m {\n    margin: 16px;\n}\n\n._1yiSxOU6DSb_D7UmCvh7yK {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n}\n._29Y2ANNHF-rGLqe2AYKuLL {\n    /* width: 50px; */\n    /* height: 50px; */\n    /* background-color: red; */\n}\n\n._1yiSxOU6DSb_D7UmCvh7yK .ant-form-item {\n    margin-bottom: 0;\n}\n._1yiSxOU6DSb_D7UmCvh7yK .ant-form-item-label {\n    line-height: initial;\n}\n._6Pbjo6_N12usRhNAoKRVL {\n    flex: 1;\n    min-width: 250px;\n    max-width: 250px;\n    min-height: 300px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n    display: flex;\n    margin: 8px;\n    flex-direction: column;\n    position: relative;\n}\n\n._25BVWEBACOwllLoFzL7sGz {\n    width: 100%;\n    padding: 8px;\n    margin-top: 16px;\n}\n\n._3-VklSoUJ_PrEA3sQcxBhQ {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n}\n\n._1h8SEK_Euv7gmYk1XRBTT5 {\n    display: flex;\n    flex-direction: row;\n    margin-top: 16px;\n    justify-content: space-between;\n    width: 420px;\n}\n", ""]);
// Exports
exports.locals = {
	"Container": "_1WlTOgl_zA0tz5VK0Q1GkI",
	"addQuestion": "_3d-dE77BzXpbzTSZbq_WTt",
	"fields": "_1WW2eJs8T4KkF5GrsZ2Dz2",
	"buttonContainer": "_33igrWOxM-cG-oj-p5aIp1",
	"subTitle": "_1GFvrpcsZ8ZdwyawPhKe4m",
	"caseTypePages": "_1yiSxOU6DSb_D7UmCvh7yK",
	"desginOptionButton": "_29Y2ANNHF-rGLqe2AYKuLL",
	"pageBox": "_6Pbjo6_N12usRhNAoKRVL",
	"questionsView": "_25BVWEBACOwllLoFzL7sGz",
	"profileSettingsFormContainar": "_3-VklSoUJ_PrEA3sQcxBhQ",
	"formItem": "_1h8SEK_Euv7gmYk1XRBTT5"
};
module.exports = exports;
