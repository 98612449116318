"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableResizePlugin = void 0;
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const react_1 = require("react");
function TableResizePlugin() {
    const [editor] = LexicalComposerContext_1.useLexicalComposerContext();
    react_1.useEffect(() => {
        let resizingCell = null;
        let startX = 0;
        let startY = 0;
        let startWidth = 0;
        let startHeight = 0;
        const handleMouseMove = (event) => {
            if (!resizingCell)
                return;
            // For horizontal resizing (columns)
            const deltaX = event.clientX - startX;
            const newWidth = Math.max(startWidth + deltaX, 10); // Minimum width of 10px
            resizingCell.style.width = `${newWidth}px`;
            // For vertical resizing (rows)
            const deltaY = event.clientY - startY;
            const newHeight = Math.max(startHeight + deltaY, 10); // Minimum height of 10px
            resizingCell.parentElement.style.height = `${newHeight}px`;
            document.body.style.cursor = resizingCell.dataset.resizeMode === 'col' ? 'col-resize' : 'row-resize';
        };
        const handleMouseUp = () => {
            document.body.style.cursor = 'default';
            if (resizingCell) {
                resizingCell = null;
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        };
        const handleMouseDown = (event) => {
            const cell = event.target instanceof HTMLElement ? event.target.closest('td, th') : null;
            if (!cell)
                return;
            const rect = cell.getBoundingClientRect();
            const mouseNearRightEdge = Math.abs(event.clientX - rect.right) < 10; // For column resizing
            const mouseNearBottomEdge = Math.abs(event.clientY - rect.bottom) < 10; // For row resizing
            if (mouseNearRightEdge || mouseNearBottomEdge) {
                event.preventDefault();
                resizingCell = cell;
                startX = event.clientX;
                startY = event.clientY;
                startWidth = rect.width;
                startHeight = rect.height;
                // Set resize mode data attribute to differentiate between column and row resizing
                if (mouseNearRightEdge) {
                    resizingCell.dataset.resizeMode = 'col';
                }
                else if (mouseNearBottomEdge) {
                    resizingCell.dataset.resizeMode = 'row';
                }
                document.addEventListener('mousemove', handleMouseMove);
                document.addEventListener('mouseup', handleMouseUp);
            }
        };
        const handleMouseOver = (event) => {
            const cell = event.target instanceof HTMLElement ? event.target.closest('td, th') : null;
            if (!cell)
                return;
            const rect = cell.getBoundingClientRect();
            const mouseNearRightEdge = Math.abs(event.clientX - rect.right) < 10;
            const mouseNearBottomEdge = Math.abs(event.clientY - rect.bottom) < 10;
            // Change cursor on hover for column and row resizing
            if (mouseNearRightEdge) {
                document.body.style.cursor = 'col-resize';
            }
            else if (mouseNearBottomEdge) {
                document.body.style.cursor = 'row-resize';
            }
            else {
                document.body.style.cursor = 'default';
            }
        };
        const editorElem = editor.getRootElement();
        if (editorElem) {
            editorElem.addEventListener('mousedown', handleMouseDown);
            editorElem.addEventListener('mousemove', handleMouseOver);
        }
        return () => {
            if (editorElem) {
                editorElem.removeEventListener('mousedown', handleMouseDown);
                editorElem.removeEventListener('mousemove', handleMouseOver);
            }
        };
    }, [editor]);
}
exports.TableResizePlugin = TableResizePlugin;
