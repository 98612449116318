// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dMp5CeCmHVlkaimMoy8ox {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex: 1;\n    height: 100vh;\n    width: 100vw;\n}\n\n._3Wg1SaWK-UkZV9Vv1rw4RT {\n    padding: 12px;\n}\n\n._3WVNBwejO4CjPwHCzY3hB7 {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    width: 120px;\n    margin: 46px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "dMp5CeCmHVlkaimMoy8ox",
	"IntegrationsContainer": "_3Wg1SaWK-UkZV9Vv1rw4RT",
	"logo": "_3WVNBwejO4CjPwHCzY3hB7"
};
module.exports = exports;
