// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Imm55g0S7B4Zz9jVzlMAX {\n    flex: 1;\n    flex-direction: row;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n}\n._2-OXkvRdH6r7G_THxfzBGV {\n    flex: 1;\n}\n\n.HDWhodA81witNc8pWO8Bq {\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding: 12px;\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"uploadImageContainar": "_3Imm55g0S7B4Zz9jVzlMAX",
	"uploadInput": "_2-OXkvRdH6r7G_THxfzBGV",
	"uploadLable": "HDWhodA81witNc8pWO8Bq"
};
module.exports = exports;
