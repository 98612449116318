// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".egDVjDNufoSHc-duETTmn ._2mzQsAC_QPukwgmU-YNXMx {\n    margin: 0 auto;\n    display: block;\n    margin-top: 12px;\n}\n\n.egDVjDNufoSHc-duETTmn {\n    display: flex;\n    flex-direction: column;\n    width: 400px;\n    box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.18);\n    border-radius: 5px;\n    padding: 32px 16px 16px 16px;\n    margin: 24px auto;\n}\n\n.e4G3QCthhCUmqNmYU1d2k {\n    display: flex;\n    width: 100vw;\n    height: 100vh;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n}\n\n._1e1VkBV6WkIdAVsCHKRQob {\n    width: 50%;\n    height: auto;\n    margin: auto;\n}\n\n._2dvJZvDuC-Y8ntNdDmjhFT {\n    display: flex;\n    justify-content: space-between;\n}\n\n._2GLJBEL6yuVK52QQH_gzyf {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto;\n}\n", ""]);
// Exports
exports.locals = {
	"formContainer": "egDVjDNufoSHc-duETTmn",
	"loginButton": "_2mzQsAC_QPukwgmU-YNXMx",
	"Container": "e4G3QCthhCUmqNmYU1d2k",
	"loginLogo": "_1e1VkBV6WkIdAVsCHKRQob",
	"changePasswordButtonContainer": "_2dvJZvDuC-Y8ntNdDmjhFT",
	"changePasswordContainer": "_2GLJBEL6yuVK52QQH_gzyf"
};
module.exports = exports;
