// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../images/icons/add-sign.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../images/icons/minus-sign.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".font-size-input {\n  font-weight: bold;\n  font-size: 14px;\n  color: #777;\n  border-radius: 5px;\n  border-color: grey;\n  height: 21px;\n  margin-top: 5px;\n  padding: 2px 4px;\n  text-align: center;\n  width: 36px;\n}\n\ninput[type='number']::-webkit-outer-spin-button,\ninput[type='number']::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.add-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n.minus-icon {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\nbutton.font-decrement {\n  padding: 0px;\n  margin-right: 3px;\n}\n\nbutton.font-increment {\n  padding: 0px;\n  margin-left: 3px;\n}\n", ""]);
// Exports
module.exports = exports;
