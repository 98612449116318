"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const antd_2 = require("antd");
const JobDetails = ({ errors, values, handleChange }) => {
    const [selectedTime, setSelectedTime] = react_1.useState(null);
    const [selectedDate, setSelectedDate] = react_1.useState(null);
    const onChange = (time, timeString) => {
        console.log(time, timeString);
        setSelectedTime(time);
    };
    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
        setSelectedDate(value);
    };
    const onPanelChangeDate = (value) => {
        setSelectedDate(value);
    };
    return (react_1.default.createElement(antd_1.Form, { layout: "vertical" },
        react_1.default.createElement(antd_1.Row, { gutter: 24 },
            react_1.default.createElement(antd_1.Col, { span: 24 },
                react_1.default.createElement(antd_1.Tabs, null,
                    react_1.default.createElement(antd_1.Tabs.TabPane, { tab: 'Immediate', key: 'Immediate' },
                        react_1.default.createElement(antd_1.Checkbox, null, "Now")),
                    react_1.default.createElement(antd_1.Tabs.TabPane, { tab: 'Schedule', key: 'Schedule', style: {
                            display: 'flex',
                        } },
                        react_1.default.createElement("div", { style: {
                                width: '300px',
                            } },
                            react_1.default.createElement(antd_1.Typography.Text, { style: {
                                    marginBottom: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignContent: 'flex-start',
                                } },
                                "Schedule for:",
                                react_1.default.createElement(antd_1.Typography.Text, { style: { justifyContent: 'flex-start', alignContent: 'flex-start' } },
                                    "Time:",
                                    react_1.default.createElement(antd_1.Typography.Text, { type: "secondary" },
                                        selectedTime ? selectedTime.format('HH:mm') : '',
                                        ' ')),
                                react_1.default.createElement(antd_1.Typography.Text, { style: { justifyContent: 'flex-start', alignContent: 'flex-start' } },
                                    "Date:",
                                    react_1.default.createElement(antd_1.Typography.Text, { type: "secondary" }, selectedDate ? selectedDate.format('YYYY / MM / DD') : '')))),
                        react_1.default.createElement("div", { style: {
                                width: '300px',
                            } },
                            react_1.default.createElement(antd_2.TimePicker, { onChange: onChange })),
                        react_1.default.createElement("div", { style: {
                                width: '300px',
                                border: '1px solid #f0f0f0',
                                borderRadius: '2px',
                            } },
                            react_1.default.createElement(antd_1.Calendar, { fullscreen: false, onPanelChange: onPanelChange, onSelect: onPanelChangeDate }))))))));
};
exports.default = JobDetails;
