"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useCompany_1 = require("./useCompany");
function useCompanyId() {
    var _a, _b;
    const company = useCompany_1.useCompany();
    const companyId = (_b = (_a = company.data) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : 'IsLoadingId';
    return companyId;
}
exports.default = useCompanyId;
