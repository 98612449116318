"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AudienceGroup2 = exports.AudienceGroup = void 0;
exports.AudienceGroup = [
    {
        name: 'Alexander Alperto',
        phoneNumber: '+ 970 343 454 56 56',
        gender: 'male',
        community: ' group 1',
    },
    {
        name: 'Bethany Brown',
        phoneNumber: '+971 555 123 456',
        gender: 'female',
        community: ' group 2',
    },
    {
        name: 'Charlie Clark',
        phoneNumber: '+972 987 654 321',
        gender: 'male',
        community: ' group 1',
    },
];
exports.AudienceGroup2 = [
    {
        name: 'BNF001',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF002',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF003',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF004',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF005',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF006',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF007',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF008',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF009',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF010',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF011',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF012',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF013',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF014',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF015',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF016',
        gender: 'Female',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF017',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF018',
        gender: 'Female',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF019',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF020',
        gender: 'Female',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF021',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF022',
        gender: 'Female',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF023',
        gender: 'Male',
        community: ' person with disability, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF024',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF025',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF026',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF027',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF028',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF029',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF030',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF031',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF032',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF033',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF034',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF035',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF036',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF037',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF038',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF039',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF040',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF041',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF042',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF043',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF044',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF045',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF046',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF047',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF048',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF049',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF050',
        gender: 'Female',
        community: ' pregnant lactating mothers, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF051',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF052',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF053',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF054',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF055',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF056',
        gender: 'Female',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF057',
        gender: 'Male',
        community: ' elderly, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF058',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF059',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF060',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF061',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF062',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF063',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF064',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF065',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF066',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF067',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF068',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF069',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF070',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF071',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF072',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF073',
        gender: 'Male',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF074',
        gender: 'Female',
        community: ' chronic illness, EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF075',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF076',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF077',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF078',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF079',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF080',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF081',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF082',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF083',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF084',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF085',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF086',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF087',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF088',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF089',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF090',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF091',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF092',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF093',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF094',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF095',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF096',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF097',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF098',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF099',
        gender: 'Male',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
    {
        name: 'BNF100',
        gender: 'Female',
        community: ' , EMERGENCY RESPONSE',
        phoneNumber: '',
    },
];
