// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2lPOfvqKJj1hOSa_qbe2dn {\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    padding-top: 8px;\n    overflow-y: auto;\n}\n\n._2MBEqQdU8HhQcaWJ0KcHgg {\n    margin: 8px;\n    border: 1px dashed #eeeeee;\n}\n\n._2lPOfvqKJj1hOSa_qbe2dn ul {\n    list-style: none;\n    padding: 0;\n}\n\n._2lPOfvqKJj1hOSa_qbe2dn li {\n    margin: 0 8px;\n    padding: 4px;\n    border-bottom: 1px solid #eee;\n    cursor: pointer;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n._2lPOfvqKJj1hOSa_qbe2dn li:hover:not(._1mk4IQIRrLltaqNAvnDhZV) {\n    background-color: rgba(24, 144, 255, 0.15);\n}\n\n._2lPOfvqKJj1hOSa_qbe2dn li:last-child {\n    border-bottom: 0;\n}\n\n.DbIJcbGejZVd2cq933D9k {\n    background-color: rgba(24, 144, 255, 0.35);\n}\n._2m1pRnn54nTj1o8RHmcnc_ {\n    color: red;\n}\n\nli._1mk4IQIRrLltaqNAvnDhZV {\n    cursor: not-allowed;\n}\n", ""]);
// Exports
exports.locals = {
	"listSelect": "_2lPOfvqKJj1hOSa_qbe2dn",
	"newListPlaceholder": "_2MBEqQdU8HhQcaWJ0KcHgg",
	"disabled": "_1mk4IQIRrLltaqNAvnDhZV",
	"selectedItem": "DbIJcbGejZVd2cq933D9k",
	"removeIcon": "_2m1pRnn54nTj1o8RHmcnc_"
};
module.exports = exports;
