// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3SZynB-wGKhOd3Tjq7bXN {\n    padding: 16px;\n    background-color: white;\n    width: 100%;\n}\n\n._37ALRPPVVBTHdseXtISYMu {\n    display: flex;\n    flex-direction: column;\n}\n\n._3g_QW1X3jCey__tF5Pk-Up {\n    display: flex;\n    justify-content: space-between;\n}\n\n._2i7QBEnAsgIUdh6fGHNMs3 {\n    margin-top: 16px;\n    margin-bottom: 16px;\n}\n\n._3vCbASCedBVzmcRkwF4NDX {\n    margin-top: 24px;\n    margin-bottom: 24px;\n    width: auto;\n    border-radius: 4px;\n    padding-top: 24px;\n    padding-bottom: 24px;\n    padding-left: 8px;\n    padding-right: 8px;\n}\n\n._12SjcH1kKEL04kPqszfZyG {\n    width: auto;\n}\n\n.lLMBaQ1mhWOX7NF0Peohz {\n    margin-top: 24px;\n    margin-bottom: 24px;\n    width: auto;\n    border-radius: 4px;\n    padding-top: 24px;\n    padding-bottom: 24px;\n    padding-left: 8px;\n    padding-right: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3SZynB-wGKhOd3Tjq7bXN",
	"attributesContainer": "_37ALRPPVVBTHdseXtISYMu",
	"actionsContainer": "_3g_QW1X3jCey__tF5Pk-Up",
	"personalInfoContainer": "_2i7QBEnAsgIUdh6fGHNMs3",
	"workExperienceItem": "_3vCbASCedBVzmcRkwF4NDX",
	"educationAndTrainingContainer": "_12SjcH1kKEL04kPqszfZyG",
	"educationAndTrainingItem": "lLMBaQ1mhWOX7NF0Peohz"
};
module.exports = exports;
