"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const lodash_1 = require("lodash");
const collectionReferences_1 = require("../../../../config/collectionReferences");
function deletePage({ pageInstances, companyLangs }) {
    lodash_1.sortBy(pageInstances, x => companyLangs === null || companyLangs === void 0 ? void 0 : companyLangs.indexOf(x.language)).map(instance => {
        collectionReferences_1.SectionsPagesInstanceCollectionRef.doc(instance.id).delete();
    });
}
exports.default = deletePage;
