"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileExtension = void 0;
function getFileExtension(filename) {
    const ext = filename.split('.').pop();
    if (ext == filename)
        return '';
    return ext;
}
exports.getFileExtension = getFileExtension;
