"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function openWindowWithParams(url, params, name, callBack) {
    const paramsString = Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    const newUrl = `${url}?${paramsString}`;
    const win = window.open(newUrl, name, 'menubar=1,resizable=1,width=650,height=850');
    return win;
}
exports.default = openWindowWithParams;
