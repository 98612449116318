"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.excelReadAndUploadN = void 0;
const lodash_1 = require("lodash");
const uuid_1 = require("uuid");
const XLSX = __importStar(require("xlsx"));
async function excelReadAndUploadN({ file, formProps, filedId, languages, }) {
    const reader = new FileReader();
    const flatMap = (array) => {
        let result = [];
        array.map(it => {
            if (lodash_1.isArray(it)) {
                result = [...result, ...it];
            }
            else if (lodash_1.isObject(it)) {
                lodash_1.map(it, value => {
                    result = [...result, value];
                });
            }
            else {
                result = [...result, it];
            }
        });
        return result;
    };
    reader.onload = async function (e) {
        var _a;
        const data = new Uint8Array((_a = e.target) === null || _a === void 0 ? void 0 : _a.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const labelsSheets = XLSX.utils.sheet_to_json(workbook.Sheets['labels']);
        const optionsSheets = XLSX.utils.sheet_to_json(workbook.Sheets['options']);
        const dfOptions = await Promise.all(labelsSheets.map(async (_labelTitles, parentIndex) => {
            const key = _labelTitles['en'];
            let options = {};
            const labelLanguages = Object.keys(_labelTitles).map(e => e);
            const oneLabelBeforeKey = labelsSheets[parentIndex - 1] !== undefined
                ? labelsSheets[parentIndex - 1]['en']
                : 'Undefined';
            const oneLabelBeforeOptions = optionsSheets
                .map((r) => {
                return r[oneLabelBeforeKey];
            })
                .filter(e => e !== undefined);
            const re = optionsSheets
                .map((r) => {
                return r[key];
            })
                .filter(e => e !== undefined);
            const val = re.map((e, idx) => {
                let localizedResult = {};
                languages.forEach(lang => {
                    const localizedValue = optionsSheets
                        .map((r) => {
                        return r[_labelTitles[lang.langKey.trim()]];
                    })
                        .filter(value => value !== undefined)[idx];
                    localizedResult[lang.langKey] = localizedValue || '';
                });
                return {
                    key: uuid_1.v4(),
                    title: e,
                    titleLocalized: localizedResult,
                    level: parentIndex,
                    ...(oneLabelBeforeOptions[idx] && { parent: oneLabelBeforeOptions[idx] }),
                };
            });
            const uniqueOptions = lodash_1.uniqBy(val, function (r) {
                return r.title;
            });
            options = { ...options, ...uniqueOptions };
            return options;
        }));
        const resetOptionsParent = (options) => {
            const result = options.map(e => {
                var _a;
                const foundParent = (_a = options.find(ot => e.parent && e.parent === ot.title)) === null || _a === void 0 ? void 0 : _a.key;
                return {
                    ...e,
                    ...(foundParent && { parent: foundParent }),
                };
            });
            return result;
        };
        const englishLabels = labelsSheets.map((e) => {
            return e['en'];
        });
        const optionsLabelsLocalized = labelsSheets.map((_Lb) => {
            let result = {};
            const labelLanguages = Object.keys(_Lb).map(e => e);
            lodash_1.map(labelLanguages.filter(e => e !== 'en'), leng => {
                result = { ...result, [leng.toLowerCase().substring(0, 2)]: _Lb[leng] };
            });
            return result;
        });
        formProps.setFieldValue(`dynamicFields.${filedId}.cascadingLevel`, labelsSheets.length);
        const buffer = {};
        optionsLabelsLocalized.map((e, level) => {
            lodash_1.map(e, (val, key) => {
                var _a;
                buffer[key] = ((_a = buffer[key]) !== null && _a !== void 0 ? _a : '') + val + '\n';
            });
            return formProps.setFieldValue(`dynamicFields.${filedId}.optionsLabelsLocalized[${level}]`, e);
        });
        if (formProps.values.dynamicFields[filedId].type === 'cascadingSelect') {
            formProps.setFieldValue(`dynamicFields.${filedId}.options`, resetOptionsParent(flatMap(dfOptions)));
            formProps.setFieldValue(`dynamicFields.${filedId}.optionsLabels`, englishLabels);
        }
        else {
            formProps.setFieldValue(`dynamicFields.${filedId}.options`, englishLabels.join('\n'));
            lodash_1.map(buffer, (x, i) => {
                formProps.setFieldValue(`dynamicFields.${filedId}.optionsLocalized[${i}]`, x.trim());
            });
        }
    };
    reader.readAsArrayBuffer(file);
}
exports.excelReadAndUploadN = excelReadAndUploadN;
