// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1kEkWcupUdgSS70-S_QqAe {\n    position: absolute;\n    top: -12px;\n    left: 12px;\n    background-color: white;\n    font-weight: 600;\n    padding: 0 4px;\n}\n\n._2ry2TZ_GqC2CwvJvLtLv92 {\n    position: absolute;\n    top: -12px;\n    right: 12px;\n    background-color: white;\n    font-weight: 600;\n    padding: 0 4px;\n}\n\n._1QXUQyOF1mvpLrJIoHmXco {\n    display: flex;\n    /* padding: 5px; */\n    /* margin: 10px 8px; */\n    justify-content: center;\n    flex-direction: column;\n    width: calc(100% - 16px);\n    border-radius: 5px;\n    position: relative;\n}\n\n._8olqAvlhMIO_JMdLiNqas {\n    margin-top: 24px;\n}\n\n._1ZkcqKCtFPjGfmN-on3lOm {\n    display: flex;\n    padding: 5px 5px;\n    margin: 10px 0px;\n    border: 1px solid rgb(204, 204, 204);\n    border-radius: 5px;\n    align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"questionFieldTitle": "_1kEkWcupUdgSS70-S_QqAe",
	"questionFieldSettings": "_2ry2TZ_GqC2CwvJvLtLv92",
	"questionContainer": "_1QXUQyOF1mvpLrJIoHmXco",
	"questionsSection": "_8olqAvlhMIO_JMdLiNqas",
	"optionBox": "_1ZkcqKCtFPjGfmN-on3lOm"
};
module.exports = exports;
