"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function convertStringToCamelCase(stringValue) {
    return stringValue
        .trim()
        .replace(/[^A-Z0-9]/gi, "")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join("");
}
exports.default = convertStringToCamelCase;
