// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".qgBhCTP0IHtR2Z1xiGyoj {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n}", ""]);
// Exports
exports.locals = {
	"hostFormContainer": "qgBhCTP0IHtR2Z1xiGyoj"
};
module.exports = exports;
