"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const antd_1 = require("antd");
const lodash_1 = require("lodash");
const slate_1 = require("slate");
const collectionReferences_1 = require("../../../../config/collectionReferences");
const PageEditorModal_1 = require("../components/PageEditorModal");
function updatePage({ t, contentValues, }) {
    lodash_1.map(contentValues, e => {
        var _a, _b;
        if (!e) {
            return;
        }
        const serializeBody = (nodes) => {
            return nodes.map((n) => slate_1.Node.string(n)).join('\n');
        };
        const serializedBody = serializeBody((_a = e === null || e === void 0 ? void 0 : e.content) !== null && _a !== void 0 ? _a : PageEditorModal_1.initialValue);
        collectionReferences_1.SectionsPagesInstanceCollectionRef.doc(e.id).set({
            content: (_b = e.content) !== null && _b !== void 0 ? _b : PageEditorModal_1.initialValue,
            title: e.title,
            body: serializedBody,
            active: true,
        }, { merge: true });
    });
    antd_1.message.success(t('app.common.savedSuccessfully'));
}
exports.default = updatePage;
