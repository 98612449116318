// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._6xI14uqmTC0a3rmqyeaHA {\n    overflow-y: scroll;\n    padding: 12;\n}\n\n.zdbCEFULDjZMG0_XUSBii {\n    top: -9999px;\n    left: -9999px;\n    position: absolute;\n    z-index: 1;\n    padding: 3px;\n    background: white;\n    border-radius: 4px;\n    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.12);\n}\n\n.bqYlTuR9vO1e5I3vGlHf {\n    right: 5%;\n    display: flex !important;\n    top: 5px;\n    height: 20px;\n}\n\n._2fC4jfKFtV32xN4I9ZlYEb {\n    padding: 1px 3px;\n    border-radius: 3px;\n}\n\n._3et4BWpEgK92TNhpeQTK3k {\n    color: '#595959';\n    font-weight: 500;\n}\n\n._1DD4f70TJYDxAEk4QjcOP3 {\n    display: flex;\n    padding: 0px 5px;\n}\n", ""]);
// Exports
exports.locals = {
	"editable": "_6xI14uqmTC0a3rmqyeaHA",
	"portal": "zdbCEFULDjZMG0_XUSBii",
	"toolbar": "bqYlTuR9vO1e5I3vGlHf",
	"chars": "_2fC4jfKFtV32xN4I9ZlYEb",
	"fullDisplayText": "_3et4BWpEgK92TNhpeQTK3k",
	"fullDisplaySiwtch": "_1DD4f70TJYDxAEk4QjcOP3"
};
module.exports = exports;
