"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.uploadToStorage = void 0;
const firebase_1 = require("firebase");
async function uploadToStorage({ destinationFileName, sourceFileName, file, setUploadProgresses, uploadingItemId, }) {
    try {
        let downloadUrl = '';
        let blob;
        if (sourceFileName) {
            const x = await fetch(sourceFileName);
            blob = await x.blob();
        }
        const uploadRef = firebase_1.storage()
            .ref()
            .child(destinationFileName);
        const uploadTask = uploadRef.put(blob !== null && blob !== void 0 ? blob : (await file.arrayBuffer()));
        uploadTask.on('state_changed', (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            if (setUploadProgresses && uploadingItemId) {
                setUploadProgresses((val) => {
                    return { ...(val ? val : {}), [uploadingItemId]: progress };
                });
            }
        }, error => {
            throw new Error(error.message);
        });
        await uploadTask;
        downloadUrl = await uploadRef.getDownloadURL();
        return downloadUrl;
    }
    catch (error) {
        console.log({ error });
    }
}
exports.uploadToStorage = uploadToStorage;
