"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getEmailStepValidation({ from, step }) {
    let errors = {};
    switch (step + '') {
        case '0': {
            if (!/\S+@\S+\.\S+/.test(from.values.email)) {
                errors = { ...errors, email: 'Invalid email ' };
            }
            if (!from.values.caseTypeId) {
                errors = { ...errors, caseType: 'Case type is required' };
            }
            break;
        }
        case '1': {
            if (isEmptyString(from.values.smtp.username)) {
                errors = { ...errors, username: 'username is required' };
            }
            if (isEmptyString(from.values.smtp.password)) {
                errors = { ...errors, password: 'password is required' };
            }
            if (isEmptyString(from.values.smtp.host)) {
                errors = { ...errors, host: 'host is required' };
            }
            if (isEmptyString(from.values.smtp.port)) {
                errors = { ...errors, port: 'port is required' };
            }
            if (isEmptyString(from.values.smtp.encryption)) {
                errors = { ...errors, encryption: 'encryption type is required' };
            }
            break;
        }
        case '2': {
            if (isEmptyString(from.values.imap.username)) {
                errors = { ...errors, username: 'username is required' };
            }
            if (isEmptyString(from.values.imap.password)) {
                errors = { ...errors, password: 'password is required' };
            }
            if (isEmptyString(from.values.imap.host)) {
                errors = { ...errors, host: 'host is required' };
            }
            if (isEmptyString(from.values.imap.port)) {
                errors = { ...errors, port: 'port is required' };
            }
            if (isEmptyString(from.values.imap.encryption)) {
                errors = { ...errors, encryption: 'encryption type is required' };
            }
            break;
        }
    }
    return errors;
}
exports.default = getEmailStepValidation;
const isEmptyString = (val) => {
    return val.trim() === '';
};
