"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertSlateDataFormatToHtml = void 0;
function convertSlateDataFormatToHtml(value) {
    let html = '';
    value.forEach((node) => {
        if (node.type === 'paragraph') {
            html += '<p>';
            node.children.forEach(child => {
                if (child.text) {
                    html += child.text;
                }
                if (child.type === 'link') {
                    html += `<a href="${child.url}">${child.url}</a>`;
                }
            });
            html += '</p>';
        }
        else if (node.type === 'heading-one') {
            html += '<h1>';
            node.children.forEach(child => {
                if (child.text) {
                    html += child.text;
                }
            });
            html += '</h1>';
        }
        else if (node.type === 'heading-two') {
            html += '<h2>';
            node.children.forEach(child => {
                if (child.text) {
                    html += child.text;
                }
            });
            html += '</h2>';
        }
        else if (node.type === 'block-quote') {
            html += '<blockquote>';
            node.children.forEach(child => {
                if (child.text) {
                    html += child.text;
                }
            });
            html += '</blockquote>';
        }
        else if (node.type === 'bulleted-list') {
            html += '<ul>';
            node.children.forEach(child => {
                html += '<li>';
                child.children.forEach(grandchild => {
                    if (grandchild.text) {
                        html += grandchild.text;
                    }
                });
                html += '</li>';
            });
            html += '</ul>';
        }
        else if (node.type === 'numbered-list') {
            html += '<ol>';
            node.children.forEach(child => {
                html += '<li>';
                child.children.forEach(grandchild => {
                    if (grandchild.text) {
                        html += grandchild.text;
                    }
                });
                html += '</li>';
            });
            html += '</ol>';
        }
        else if (node.type === 'image') {
            const url = node.url || '';
            html += `<img src="${url}" alt="Image" />`;
        }
        else if (node.type === 'link') {
            const url = node.url || '';
            html += `<a href="${url}">`;
            node.children.forEach(child => {
                if (child.text) {
                    html += child.text;
                }
            });
            html += '</a>';
        }
        else if (node.type === 'mention') {
            html += `<span class="mention">${node.value}</span>`;
        }
    });
    return html;
}
exports.convertSlateDataFormatToHtml = convertSlateDataFormatToHtml;
