// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LNgZ4bw9GWF0MNrjtr3ZM {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    min-height: 400px;\n}\n\n._2WRztTN3bb4PygCJn5JUPO {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    min-height: 180px;\n}\n\n._3yUA12r1Tu_a74oOqNey0b {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    min-height: 180px;\n}\n\n._4wZ1bBypz-ICflO8nrgcI {\n    padding: 22px;\n}\n", ""]);
// Exports
exports.locals = {
	"tabContainer": "LNgZ4bw9GWF0MNrjtr3ZM",
	"translatedProgress": "_2WRztTN3bb4PygCJn5JUPO",
	"uploadedPercent": "_3yUA12r1Tu_a74oOqNey0b",
	"dragger": "_4wZ1bBypz-ICflO8nrgcI"
};
module.exports = exports;
