"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.eLearningStudentUpdateViewedList = void 0;
const collectionReferences_1 = require("../config/collectionReferences");
async function eLearningStudentUpdateViewedList({ docRef, sectionId, chapterId, moduleId, userType, }) {
    const studentDocument = await collectionReferences_1.eLearningStudentsCollectionRef.doc(docRef).get();
    let { viewedChaptersIds, viewedModulesIds, viewedSectionsIds, companyId, } = studentDocument.data();
    const viewModuleRecord = await collectionReferences_1.modulesViewRecordsCollectionRef
        .where('moduleId', '==', moduleId)
        .where('studentId', '==', docRef)
        .get();
    const viewChapterRecord = await collectionReferences_1.chaptersViewRecordsCollectionRef
        .where('moduleId', '==', moduleId)
        .where('chapterId', '==', chapterId)
        .where('studentId', '==', docRef)
        .get();
    const viewSectionRecord = await collectionReferences_1.sectionsViewRecordsCollectionRef
        .where('moduleId', '==', moduleId)
        .where('studentId', '==', docRef)
        .get();
    if (viewModuleRecord.docs[0] === undefined) {
        collectionReferences_1.modulesViewRecordsCollectionRef.add({
            companyId,
            date: new Date(),
            moduleId,
            studentId: docRef,
            studentType: userType,
        });
    }
    if (viewChapterRecord.docs[0] === undefined) {
        collectionReferences_1.chaptersViewRecordsCollectionRef.add({
            chapterId,
            companyId,
            date: new Date(),
            moduleId,
            studentId: docRef,
            studentType: userType,
        });
    }
    if (viewSectionRecord.docs[0] === undefined) {
        collectionReferences_1.sectionsViewRecordsCollectionRef.add({
            chapterId,
            companyId,
            date: new Date(),
            moduleId,
            sectionId,
            studentId: docRef,
            studentType: userType,
        });
    }
    let status = '';
    if (!viewedModulesIds.includes(moduleId)) {
        viewedModulesIds = [...viewedModulesIds, moduleId];
        await studentDocument.ref.update({
            viewedModulesIds,
        });
        status = 'viewedModules';
    }
    if (!viewedChaptersIds.includes(chapterId)) {
        viewedChaptersIds = [...viewedChaptersIds, chapterId];
        await studentDocument.ref.update({
            viewedChaptersIds,
        });
        status = 'viewedChapter';
    }
    if (!viewedSectionsIds.includes(sectionId)) {
        viewedSectionsIds = [...viewedSectionsIds, sectionId];
        await studentDocument.ref.update({
            viewedSectionsIds,
        });
        status = 'viewedSection';
    }
    return status;
}
exports.eLearningStudentUpdateViewedList = eLearningStudentUpdateViewedList;
