"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CertificateBox = exports.certificateDownloadingPromises = void 0;
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const react_i18next_1 = require("react-i18next");
const app_1 = require("firebase/app");
const file_saver_1 = require("file-saver");
const { Text } = antd_1.Typography;
exports.certificateDownloadingPromises = new Map();
exports.CertificateBox = (props) => {
    const { fileName, id, certificateLang, date, moduleName, fullName, certificatePath } = props;
    const [loading, setLoading] = react_1.useState(true);
    const [downloadUrl, setDownloadUrl] = react_1.useState(null);
    const { t } = react_i18next_1.useTranslation();
    react_1.useEffect(() => {
        const fetchDownloadUrl = async () => {
            try {
                const url = await app_1.storage()
                    .ref(certificatePath)
                    .getDownloadURL();
                setDownloadUrl(url);
                setLoading(false);
            }
            catch (error) {
                console.error('Error fetching download URL:', error);
                setLoading(false);
            }
        };
        fetchDownloadUrl();
    }, [certificatePath]);
    const openFile = react_1.useCallback(() => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
        else {
            antd_1.message.error(t('app.generateCertificate.downloadFailed'));
        }
    }, [downloadUrl, t]);
    const sharePDFWithBrowser = react_1.useCallback(() => {
        if (!downloadUrl) {
            antd_1.message.error(t('app.generateCertificate.downloadFailed'));
            return;
        }
        file_saver_1.saveAs(downloadUrl, `${fileName}.pdf`);
        antd_1.message.success(t('app.generateCertificate.shareSuccess'));
    }, [downloadUrl, fileName, t]);
    return (react_1.default.createElement("div", { style: styles.box }, loading ? (react_1.default.createElement(antd_1.Spin, null)) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { style: styles.attachmentDescription },
            react_1.default.createElement(Text, { strong: true },
                t('app.generateCertificate.name'),
                ": "),
            react_1.default.createElement(Text, null, fullName)),
        react_1.default.createElement("div", { style: styles.attachmentDescription },
            react_1.default.createElement(Text, { strong: true },
                t('app.generateCertificate.moduleName'),
                ": "),
            react_1.default.createElement(Text, null, moduleName)),
        react_1.default.createElement("div", { style: styles.attachmentDescription },
            react_1.default.createElement(Text, { strong: true },
                t('app.generateCertificate.date'),
                ": "),
            react_1.default.createElement(Text, null, date.toDate().toDateString())),
        react_1.default.createElement("div", { style: styles.buttonsGroup },
            react_1.default.createElement(antd_1.Button, { onClick: openFile, icon: react_1.default.createElement(icons_1.EyeOutlined, null), type: "primary", style: styles.optionButton }, t('app.generateCertificate.view')),
            react_1.default.createElement(antd_1.Button, { onClick: sharePDFWithBrowser, icon: react_1.default.createElement(icons_1.ShareAltOutlined, null), type: "default", style: styles.optionButton }, t('app.generateCertificate.share')))))));
};
const styles = {
    box: {
        padding: '16px',
        border: '1px solid #f0f0f0',
        borderRadius: '8px',
        marginBottom: '16px',
        display: 'flex',
        flexDirection: 'column',
    },
    attachmentDescription: {
        marginBottom: '8px',
        color: '#595959',
    },
    buttonsGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    optionButton: {
        width: '120px',
    },
};
