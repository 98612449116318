"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const react_i18next_1 = require("react-i18next");
const icons_1 = require("@ant-design/icons");
const lodash_1 = require("lodash");
function AddLink({ formProps, attachment }) {
    var _a, _b, _c;
    const { t } = react_i18next_1.useTranslation();
    const inputValue = (_c = (_b = (_a = formProps === null || formProps === void 0 ? void 0 : formProps.values.attachments) === null || _a === void 0 ? void 0 : _a.find(x => x.id === attachment.id)) === null || _b === void 0 ? void 0 : _b.url) !== null && _c !== void 0 ? _c : '';
    const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    function isValidURL(str) {
        const pattern = new RegExp(expression);
        return !!pattern.test(str);
    }
    const isValued = inputValue.trim() !== '' && isValidURL(inputValue);
    const onTextChange = react_1.useCallback(e => {
        var _a;
        const text = e.target.value;
        const at = formProps === null || formProps === void 0 ? void 0 : formProps.values.attachments.find(x => x.id === attachment.id);
        const newAttachmentText = { ...at, url: text.toLocaleLowerCase() };
        const filteredattachmentArray = (_a = formProps === null || formProps === void 0 ? void 0 : formProps.values.attachments) === null || _a === void 0 ? void 0 : _a.filter(x => x.id !== attachment.id);
        formProps === null || formProps === void 0 ? void 0 : formProps.setFieldValue('attachments', [...(filteredattachmentArray !== null && filteredattachmentArray !== void 0 ? filteredattachmentArray : []), newAttachmentText]);
    }, [attachment.id, formProps]);
    react_1.useEffect(() => {
        if (!isValued) {
            formProps === null || formProps === void 0 ? void 0 : formProps.setErrors({ [attachment.id]: 'Not Valid Link' });
            return;
        }
        if (isValued && formProps && Object.keys(formProps.errors).some(x => x === attachment.id)) {
            formProps.setErrors(lodash_1.omit(formProps.errors, attachment.id));
            return;
        }
    }, [attachment.id, formProps, isValued]);
    const removeAttachment = react_1.useCallback(() => {
        const newAttachmentArray = formProps.values.attachments.filter(at => at.id !== attachment.id);
        formProps.setFieldValue('attachments', newAttachmentArray);
    }, [attachment.id, formProps]);
    const openLink = react_1.useCallback(() => { }, [attachment.url]);
    const renderLinkImage = react_1.useCallback(() => {
        return null;
    }, []);
    return (react_1.default.createElement(antd_1.Space.Compact, null,
        react_1.default.createElement(antd_1.Input, { style: { color: isValued ? '#888' : 'red' }, placeholder: "#Link", value: inputValue, onChange: onTextChange }),
        react_1.default.createElement(antd_1.Button, { onClick: removeAttachment },
            react_1.default.createElement(icons_1.DeleteOutlined, null))));
}
exports.default = react_1.default.memo(AddLink);
